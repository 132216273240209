<template>
  <div class="wrapper col-md-12">
    <div class="form-group row">
      <button
        class="back btn btn-sm btn-outline-secondary shadow-none"
        @click="back"
      >
        <i class="bi bi-arrow-left"></i> назад
      </button>
      <input
        type="text"
        class="form-control shadow-none col-md-2"
        placeholder="поиск по наименованию"
        @input="search"
        v-model="dataSearch"
      />
      <button
        class="btn btn-sm btn-outline-success shadow-none"
        @click="addNeUser"
      >
        добавить пользователя
      </button>
    </div>
    <div class="overflow-auto">
      <table class="table table-sm" v-if="userList && userList.length > 0">
        <tr>
          <th></th>
          <th>логин</th>
          <th>наименование</th>
          <th>расценки</th>
          <th>ТК</th>
          <th>договор</th>
          <th>email</th>
          <th>менеджер</th>
          <th>регион</th>
          <th>последняя дата входа</th>
        </tr>
        <tr
          v-for="(item, idx) in userList"
          :key="idx"
          @click="goUser(item.id)"
          class="cursor"
          :class="item.aktiv === '1' ? 'off' : null"
        >
          <td>{{ idx + 1 }}</td>
          <td>{{ item.login }}</td>
          <td>{{ item.user }}</td>
          <td>{{ item.report_price }}</td>
          <td>{{ item.user_name }}</td>
          <td>{{ item.nomer_dog }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.menedger }}</td>
          <td>{{ item.region }}</td>
          <td>{{ item.date_login }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
export default {
  name: "Users",
  data: () => ({
    userList: null,
    userListFilter: null,
    dataSearch: ""
  }),
  mounted() {
    this.getData();
  },
  methods: {
    back() {
      if (this.GET_PATH_FROM === "/login") {
        this.$router.push("/home");
      } else {
        this.$router.go(-1);
      }
    },
    getData() {
      HTTPGET({
        url: "/admin/users/get_list_users.php"
      }).then(response => {
        this.userList = this.userListFilter = response.data;
      });
    },
    goUser(id) {
      this.$router.push(`/kabinet/users/user/${id}`);
    },
    addNeUser() {
      this.$router.push(`/kabinet/users/add-new-user`);
    },
    search() {
      this.userList = this.userListFilter.filter(item =>
        item.user.toLowerCase().includes(this.dataSearch.toLowerCase())
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  margin: 10px;
  max-width: 99%;
}
table {
  font-size: 0.7em;
  margin-top: 10px;
}
.cursor {
  cursor: pointer;
}
th {
  vertical-align: middle;
}
.off {
  background-color: #ff0000;
  color: #ffffff;
}
button,
input {
  margin-right: 10px;
}
.row {
  padding-left: 10px;
}
</style>
